<template>
    <smu-main-menu>
        <smu-menu-section
            v-for="section in filteredSections"
            :key="section.name"
            :name="section.name"
            :has-active-links="sectionHasActiveLinks(section.links)"
        >
            <template v-for="item in section.links" :key="item.name">
                <smu-menu-section-entry
                    :is-beta="item.beta"
                    :is-dev="item.dev"
                    :is-active="isActive(item.url)"
                    class="w-full"
                >
                    <nuxt-link
                        v-if="linkIsAuthorized(item)"
                        :to="item.url"
                        class="flex h-full w-full gap-4 active:text-primary"
                        active-class="text-primary"
                    >
                        <span class="w-6">
                            <smu-icon v-bind="item.icon" />
                        </span>

                        {{ item.name }}
                    </nuxt-link>
                </smu-menu-section-entry>
            </template>
        </smu-menu-section>
    </smu-main-menu>

    <div
        v-if="isAuthorised({ permission: 'manage go-to-legacy-backend' })"
        class="mt-8"
    >
        <menu-redirect />
    </div>
</template>

<script setup lang="ts">
import type { SmuIconProps } from "@smakecloud/smake-ui";

type Link = {
    name: string;
    icon: SmuIconProps;
    url: string;
    dev?: boolean;
    beta?: boolean;
    permission?: string;
};

type Section = {
    name: string;
    links: Link[];
};

const { t } = useI18n();
const { isAuthorised } = useAuthorisationChecker();
const router = useRouter();
const route = useRoute();
const slug = useSlug();

function isActive(url: string) {
    const regex = new RegExp(url + ".*$");
    return regex.test(route.path);
}

function sectionHasActiveLinks(links: Link[]) {
    for (const link of links) {
        if (isActive(link.url)) {
            return true;
        }
    }

    return false;
}

const linkIsAuthorized = (link: Link) => {
    return isAuthorised(link);
};

const sections = ref<Section[]>([
    {
        name: "",
        links: [
            {
                name: t("shop/backend.dashboard.menu.title"),
                icon: {
                    type: "light",
                    icon: "tachometer-alt",
                },
                url: `/${slug.value}/dashboard`,
            },
        ],
    },
    {
        name: t("production.menue.goods"),
        links: [
            {
                name: t("production.stock-items.list.title"),
                icon: {
                    type: "light",
                    icon: "inventory",
                },
                url: `/${slug.value}/inventory`,
            },
        ],
    },
    {
        name: t("eloquent.attributes.tenant.settings"),
        links: [
            {
                name: t("eloquent.attributes.production_method.title"),
                icon: {
                    type: "light",
                    icon: "gem",
                },
                url: `/${slug.value}/production-methods`,
            },
        ],
    },
]);

const filteredSections = computed(() =>
    sections.value.filter(
        (section) =>
            section.links.filter((link) => {
                const route = router.resolve(link.url);

                return isAuthorised(
                    route.matched.length === 0 ? link : route.meta,
                );
            }).length > 0,
    ),
);

const productionProductionStore = useProductionProductionStore();

const { data: productionProductionMethods } = storeToRefs(
    productionProductionStore,
);

const productionMethods = computed<Link[]>(
    () =>
        productionProductionMethods.value?.data
            .filter((method) => method.is_using_presorting)
            .map((method) => ({
                name:
                    method.production_method.display_name ??
                    method.production_method_handle,
                icon: {
                    type:
                        method.production_method_handle === "dtf"
                            ? "kit"
                            : "light",
                    icon:
                        method.production_method_handle === "dtf"
                            ? method.production_method_handle
                            : "container-storage",
                },
                url: `/${slug.value}/presorting/${method.id}`,
                permission: "manage presorting",
            })) ?? [],
);

watchEffect(() => {
    if (productionMethods.value) {
        let section = sections.value.find(
            (section) => section.name === t("helpers.common.presorting"),
        );

        if (!section) {
            section = {
                name: t("helpers.common.presorting"),
                links: [],
            };
            sections.value.push(section);
        }

        productionMethods.value.forEach((newLink) => {
            const linkExists = section.links.some(
                (existingLink) => existingLink.name === newLink.name,
            );

            if (!linkExists) {
                section.links.push(newLink);
            }
        });
    }
});
</script>
