<template>
    <smu-menu-dropdown v-if="languages" :items="Object.keys(languages)">
        <template #value>{{ locale.toUpperCase() }}</template>
        <template #item="{ item }">
            <div class="w-full p-3" @click="setLocale(item)">
                {{ languages[item] }}
            </div>
        </template>
    </smu-menu-dropdown>
</template>

<script setup lang="ts">
const { setLocale } = useLocalSettingsStore();
const { locale } = storeToRefs(useLocalSettingsStore());

const { data } = storeToRefs(useUserStore());

const languages = computed(() =>
    data.value?.locales.reduce<Record<string, string>>(
        (accumulator, { code, name }) => ({
            ...accumulator,
            [code]: name,
        }),
        {},
    ),
);
</script>
