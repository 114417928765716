<template>
    <div v-if="resetFn && saveFn && hasChanges">
        <smu-dialog :has-close-button="false" :open="isModalOpen">
            <template #title>
                {{ $t("helpers.confirm.title") }}
            </template>
            <div class="flex w-full flex-col lg:w-[35rem]">
                <span class="my-3">
                    {{ $t("helpers.confirm.unsaved_changes_content") }}
                </span>
            </div>
            <template #footerContent>
                <div class="flex flex-wrap justify-center sm:justify-end">
                    <smu-button
                        color="secondary"
                        type="outline"
                        class="m-1"
                        @click="toggleModal(false)"
                    >
                        {{ $t("helpers.buttons.continue_edit") }}
                    </smu-button>
                    <smu-button
                        color="danger"
                        class="m-1"
                        @click="
                            toggleModal(false);
                            resetFn();
                        "
                    >
                        {{ $t("helpers.buttons.discard_changes") }}
                    </smu-button>
                </div>
            </template>
        </smu-dialog>
        <div class="flex h-full w-full justify-between text-sm text-white">
            <div
                class="my-auto flex w-full text-left align-middle md:w-1/2 md:text-center"
            >
                <span>
                    <smu-icon
                        type="light"
                        icon="triangle-exclamation"
                        class="mr-1"
                    />
                    <span
                        class="px-1 font-light text-wrap text-white max-sm:hidden"
                    >
                        {{ $t("helpers.common.unsaved_changes") }}
                    </span>
                </span>
            </div>
            <div class="flex flex-1 items-center justify-end whitespace-nowrap">
                <div class="flex space-x-2">
                    <smu-button
                        class="h-8 border-none bg-text hover:bg-text-4 dark:bg-text dark:hover:bg-text-4"
                        size="sm"
                        :disabled="isLoading"
                        @click="toggleModal"
                    >
                        {{ $t("helpers.buttons.discard") }}
                    </smu-button>
                    <smu-button
                        class="h-8 border-none"
                        color="primary"
                        size="sm"
                        :disabled="isLoading"
                        @click="saveFn"
                    >
                        <template #iconLeft>
                            <div v-if="isLoading">
                                <smu-icon
                                    type="light"
                                    icon="spinner"
                                    :spin-pulse="true"
                                />
                            </div>
                        </template>

                        {{ $t("helpers.buttons.save") }}
                    </smu-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const { hasChanges, saveFn, resetFn, isLoading, toggleModal, isModalOpen } =
    useUnsavedChangesHeader();
</script>
